import React, { FC, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Form, Input, Button, Spin, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useRequestWithoutJWT, headersWithoutJWT } from '../../utils'

interface IValues {
  username: string
  password: string
}

const LoginForm: FC = () => {
  const name = localStorage.getItem('name')
  const jwt = localStorage.getItem('jwt')
  const [isLogined, setIsLogined] = useState<boolean>(
    name != null && jwt != null
  )

  /**
   * 登录
   */
  const { loading, run } = useRequestWithoutJWT({
    service: (params: IValues) => ({
      url: `/auth/v1/login/tool`,
      method: 'POST',
      headers: {...headersWithoutJWT,...params},
      // body: JSON.stringify(params),
    }),
    onSuccess: (res) => {
      const { data } = res || {}
      const { name, jwt } = data || {}

      localStorage.setItem('name', `${name}`)
      localStorage.setItem('jwt', `Bearer ${jwt}`)
      setIsLogined(true)
      message.success('登录成功')
    },
  })

  /**
   * 点击确定按钮
   */
  const onFinish = (values: IValues) => {
    run(values)
  }

  return isLogined ? (
    <Redirect to="/" />
  ) : (
    <Spin spinning={loading}>
      <Form onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: '请输入用户名!' }]}
        >
          <Input
            maxLength={11}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="用户名"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: '请输入密码!' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="密码"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="appLogin_btn"
            size="large"
            disabled={loading}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default LoginForm
