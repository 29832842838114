import React, { FC } from 'react'
import { Form, Input, message, Modal, Spin } from 'antd'
import { useRequestWithJWT, headersWithJWT } from '../utils'

interface IValues {
  again_new_pwd: string
  new_pwd: string
  origin_pwd: string
}

interface IProps {
  visible: boolean
  hideModal: () => void
  logout: () => void
}

const ModifyPasswordForm: FC<IProps> = (props) => {
  const { visible, hideModal, logout } = props
  const [form] = Form.useForm()
  const { loading, run } = useRequestWithJWT(
    {
      service: (params: IValues) => ({
        url: `/tool/v1/modify_pwd`,
        method: 'PUT',
        headers: headersWithJWT(),
        body: JSON.stringify(params),
      }),
      onSuccess: () => {
        logout()

        message.success('修改成功,请重新登录')
      },
    }
  )

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    form.validateFields().then((values: IValues) => {
      run(values)
    })
  }

  return (
    <Modal
      title={`修改密码`}
      destroyOnClose={true}
      // getContainer={false}
      closable={!loading}
      keyboard={!loading}
      maskClosable={!loading}
      visible={visible}
      okText="提交"
      onOk={handleSubmit}
      okButtonProps={{ disabled: loading }}
      cancelText="取消"
      onCancel={hideModal}
      cancelButtonProps={{ disabled: loading }}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: '${label}不能为空!' }}
        >
          <Form.Item
            label="旧密码"
            name="origin_pwd"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="请输入旧密码" />
          </Form.Item>

          <Form.Item label="新密码" name="new_pwd" rules={[{ required: true }]}>
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>

          <Form.Item
            label="确认新密码"
            name="again_new_pwd"
            dependencies={['new_pwd']}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_pwd') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('两次输入的密码不一致!')
                },
              }),
            ]}
          >
            <Input.Password placeholder="请再次输入新新密码" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default ModifyPasswordForm
