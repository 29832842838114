import React, { FC, useState, Key } from 'react'
import { Layout, message, Spin, Modal } from 'antd'
import CarTree from './CarTree'
import TaskForm, { IUpgradeValues, IRollbackValues } from './TaskForm'
import { useRequestWithJWT, headersWithJWT } from '../../../utils'

import './index.less'

const { Content } = Layout

interface IParams extends IUpgradeValues, IRollbackValues {
  vehicle_ids: string[]
}

const OTATasks: FC = () => {
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([])
  const [isReload, setIsReload] = useState<boolean>(false)

  /**
   * 升级/回退
   */
  const { loading, run } = useRequestWithJWT({
    service: (params: IParams) => {
      const { operation } = params

      const url =
        operation === 'upgrade'
          ? `/tool/v1/ota/task/upgrade`
          : `/tool/v1/ota/task/rollback`

      return {
        url,
        method: 'POST',
        headers: headersWithJWT(),
        body: JSON.stringify(params),
      }
    },
    onSuccess: () => {
      setIsReload(true)
      message.success('操作成功')
      window.location.reload()
    },
  })

  /**
   * 点击确定按钮触发
   */
  const onSubmit = (values: IUpgradeValues | IRollbackValues) => {
    Array.isArray(checkedKeys) && checkedKeys.length > 0
      ? run({ ...values, vehicle_ids: checkedKeys })
      : Modal.error({
          title: '请选择需要执行操作的车辆',
        })
  }

  return (
    <Spin spinning={loading || isReload}>
      <Content className="app_main">
        <div className="app_content OTATasks">
          <CarTree setCheckedKeys={setCheckedKeys} />

          <TaskForm onSubmit={onSubmit} />
        </div>
      </Content>
    </Spin>
  )
}

export default OTATasks
