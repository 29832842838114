import { Button } from 'antd'
import { Link } from 'react-router-dom'

export const getTableColumns = (deleteRouteCollect: (id: string) => any) => [
  {
    title: '路线ID',
    dataIndex: 'id',
    key: 'id',
    render: (id: string) => (
      <Link to={`/routeCollect/routeCollectDetails/${id}`} target="_blank">
        {id}
      </Link>
    ),
  },
  {
    title: '路线里程（千米）',
    dataIndex: 'mileage',
    key: 'mileage',
    render: (mileage: number) => {
      if (typeof mileage === 'number') {
        return mileage.toFixed(1)
      } else {
        return '-'
      }
    },
  },
  {
    title: '固定解占比',
    dataIndex: 'fixed_solution_rate',
    key: 'fixed_solution_rate',
    render: (fixed_solution_rate: number) => {
      if (typeof fixed_solution_rate === 'number') {
        return `${fixed_solution_rate.toFixed(1)}%`
      } else {
        return '-'
      }
    },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '路线描述',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '操作',
    key: 'action',
    render: (item: { id: string }) => (
      <Button
        className="m-5"
        danger={true}
        size="small"
        onClick={deleteRouteCollect(item.id)}
      >
        删除
      </Button>
    ),
  },
]
