import { Button, Modal, Space, Badge } from 'antd'
import { ReactNode } from 'react'

interface IVersion {
  pad: string
  master_machine: string
  chassis: string
  tight_coupling: string
}

export const getTableColumns = (showOTAFailedDetail: (id: string) => any) => {
  /**
   * 展示升级状态
   */
  const showStatusInfo = (status: string, id: string): ReactNode => {
    switch (status) {
      case 'not_started':
        return <Badge status="default" text="未开始" />

      case 'failed':
        return (
          <Button size="small" onClick={showOTAFailedDetail(id)}>
            <Badge
              status="error"
              text={<span className="text-red">已失败</span>}
            />
          </Button>
        )

      case 'timed_out':
        return (
          <Button size="small" onClick={showOTAFailedDetail(id)}>
            <Badge
              status="error"
              text={<span className="text-red">已超时</span>}
            />
          </Button>
        )

      case 'success':
        return <Badge status="success" text="已完成" />

      case 'in_progress':
        return <Badge status="processing" text="进行中" />

      default:
        return status
    }
  }

  return [
    {
      title: '景区名称',
      dataIndex: 'site_name',
      key: 'site_name',
    },
    {
      title: '车牌号',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: '车辆状态',
      dataIndex: 'vehicle_status',
      key: 'vehicle_status',
      render: (status: string) => getCarStatus(status),
    },
    {
      title: '添加时间',
      dataIndex: 'add_time',
      key: 'add_time',
    },
    {
      title: '升级方式',
      dataIndex: 'operation',
      key: 'operation',
      render: (operation: string) => showOperation(operation),
    },
    {
      title: '升级状态',
      dataIndex: 'upgrade_status',
      key: 'upgrade_status',
      render: (status: string, item: { id: string }) =>
        showStatusInfo(status, item.id),
    },
    {
      title: '当前进度',
      dataIndex: 'progress',
      key: 'progress',
      render: (progress: string) => `${progress || 0}%`,
    },
    {
      title: '当前版本',
      key: 'version',
      render: (version: IVersion) => (
        <Button
          size="small"
          type="link"
          onClick={() => showVersionInfo(version)}
        >
          详情
        </Button>
      ),
    },
    {
      title: '更新说明',
      dataIndex: 'task_des',
      key: 'task_des',
      render: (text: string) => (
        <Button size="small" type="link" onClick={() => showUpdateInfo(text)}>
          详情
        </Button>
      ),
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      key: 'operator',
    },
  ]
}

/**
 * 展示车辆状态
 */
const getCarStatus = (status: string): string => {
  switch (status) {
    case 'maintain':
      return '维保中'

    case 'waitingForRent':
      return '待租用'

    case 'reserved':
      return '预分配'

    case 'returning':
      return '还车中'

    case 'operationLock':
      return '运营锁车'

    case 'operationReady':
      return '运营就绪'

    case 'operationManual':
      return '运营手动'

    case 'operationAuto':
      return '运营自动'

    case 'operationFault':
      return '运营故障'

    case 'maintenanceLock':
      return '维护锁车'

    case 'maintenanceReady':
      return '维护就绪'

    case 'maintenanceManual':
      return '维护手动'

    case 'maintenanceFault':
      return '维护故障'

    case 'shutting':
      return '关机中'

    case 'offline':
      return '离线'

    default:
      return status
  }
}

/**
 * 展示升级方式
 */
const showOperation = (operation: string): ReactNode => {
  switch (operation) {
    case 'upgrade':
      return '升级'

    case 'rollback':
      return '回退'

    default:
      return operation
  }
}

/**
 * 展示当前版本
 */
const showVersionInfo = (version: IVersion) =>
  Modal.info({
    title: '当前版本',
    content: (
      <Space direction="vertical">
        <p>Pad版本：{version.pad}</p>
        <p>上位机版本：{version.master_machine}</p>
        <p>下位机版本：{version.chassis}</p>
        <p>紧组合版本：{version.tight_coupling}</p>
      </Space>
    ),
  })

/**
 * 展示更新说明
 */
const showUpdateInfo = (text: string) =>
  Modal.info({
    title: '更新说明',
    content: <div dangerouslySetInnerHTML={{ __html: text }} />,
  })
