import React, { FC } from 'react'
import LoginFrom from './LoginForm'

import './index.less'

import logo from '../../images/logo_black.svg'

const Login: FC = () => {
  return (
    <div className="appLogin">
      <div className="appLogin_content">
        <div className="appLogin_logo text-center">
          <img src={logo} alt="logo" />
        </div>

        <div className="text-center fs-28 mt-10 mb-30 appLogin_title">
          金瑞麒工具管理平台
        </div>

        <LoginFrom />
      </div>
    </div>
  )
}

export default Login
