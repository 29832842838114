import { PureComponent } from 'react'
import {
  Layout,
  Card,
  Table,
  Input,
  Button,
  Row,
  Col,
  message,
  Modal,
} from 'antd'
import { request, cardBodyStyle } from '../../utils/log'
import WrappedOpForm from './opform'
const { Content } = Layout

const columns = [
  {
    title: '文件名',
    dataIndex: 'file_name',
    key: 'file_name',
    render: (value: string) =>
      value ? value.substring(value.lastIndexOf('/') + 1) : '-',
  },
  {
    title: '文件大小',
    dataIndex: 'size',
    key: 'size',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    render: (value: string) => (value ? value : '-'),
  },
  {
    title: '下载',
    dataIndex: 'is_file',
    key: 'is_file',
    render: (value: boolean, item: { url: string }) =>
      value ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={item.url} download={item.url} target="_blank">
          下载
        </a>
      ) : (
        '-'
      ),
  },
]

export interface IParam {
  vehicle_id: string
  service: string
  start_time?: string
  end_time?: string
  module?: string[]
  type?: string
  attribute?: string
}

export interface ILogFile {
  file_name: string
  size: number
  url: string
  create_time: string
  is_file: boolean
  children?: ILogFile[]
}

export interface IVehicle {
  device_id: string
  label: string
  value: string
}

interface IState {
  logFiles: ILogFile[]
  vehicles: IVehicle[]
  device_id: string
  user_id: string
  path: string
  logCategory: number
  rowsKey: number
}

let searchCount = 0

export default class LogManage extends PureComponent<{}, IState> {
  public readonly state: Readonly<IState> = {
    logFiles: [],
    vehicles: [],
    device_id: '',
    user_id: '',
    logCategory: 0,
    path: '',
    rowsKey:1,
  }

  public componentDidMount() {
    this.getVehicles()
  }
  public render() {
    return (
      <Content className="app_main">
        <div className="app_content">
          <Row>
            <Col span={16}>
              <Card
                title="车辆日志"
                style={{ minWidth: 340, margin: 8 }}
                bodyStyle={cardBodyStyle}
              >
                <WrappedOpForm
                  searchLog={this.searchVecLog}
                  searchLogUploadStatus={this.searchLogUploadStatus}
                  sendVehicleLogCmd={this.sendVehicleLogCmd}
                  vehicles={this.state.vehicles}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="小程序/管理端日志"
                style={{ minWidth: 340, margin: 8 }}
                bodyStyle={cardBodyStyle}
              >
                <Input
                  placeholder="用户ID"
                  style={{ width: 180 }}
                  onChange={this.onUserIdChange}
                />
                {/* <Button style={{ marginLeft: 8 }} type="primary" onClick={this.sendMiniLogCmd}>下发</Button> */}
                <Button
                  style={{ marginLeft: 8 }}
                  type="primary"
                  onClick={this.searchMinicLog}
                >
                  查询小程序日志
                </Button>
                <Button
                  style={{ marginLeft: 8 }}
                  type="primary"
                  onClick={this.searchAdminLog}
                >
                  查询管理端日志
                </Button>
              </Card>
            </Col>
          </Row>

          <Table
            style={{ margin: 12 }}
            rowKey="file_name"
            key={this.state.rowsKey}
            locale={{ emptyText: '暂无日志' }}
            columns={columns}
            dataSource={this.state.logFiles}
            onExpand={this.searchChildrenLog}
            pagination={{
              pageSize: 200,
              current: 1,
              total: 200,
            }}
          />
        </div>
      </Content>
    )
  }

  private getVehicles = () => {
    request({
      config: { url: `/getVehicleInfo`, method: 'GET' },
      success: (resData, showModal) => {
        const { code, data } = resData
        if (code === 0) {
          this.setState({
            vehicles: data.vehicles_info,
          })
        } else {
          showModal()
        }
      },
      fail: (showModal) => showModal(),
      error: () => console.log('error'),
    })
  }

  private sendVehicleLogCmd = (flag: number, param?: IParam) => {
    const rurl = flag === 0 ? `/logUploadNotice` : `/logUploadNotice/tx2`

    request({
      config: { url: rurl, method: 'POST', data: param },
      success: (resData, showModal) => {
        const { code } = resData
        if (code === 0) {
          message.success('操作成功')
        } else {
          showModal()
        }
      },
      fail: (showModal) => showModal(),
      error: () => console.log('error'),
    })
  }
  private searchVecLog = (vid: string) => {
    console.log('searchVecLog:' + vid)
    //刷新table组件
    this.setState(
      {
        device_id: vid,
        logCategory: 0,
        rowsKey:Math.random()*100
      },
      () => {
        this.searchLog(vid)
      }
    )
  }
  private searchLogUploadStatus = (vid: string) => {
    console.log('searchLogUploadStatus:' + vid)
    request({
      config: {
        url: `/tx2UploadStatus`,
        method: 'GET',
        params: { vehicle_id: vid },
      },
      success: (resData, showModal) => {
        const { code, data } = resData
        if (code === 0) {
          // message.success("上传进度:"+data.progress+",上传结果:"+data.result)
          Modal.success({
            content:
              '上传进度:' +
              data.progress +
              ',上传结果:' +
              data.result +
              ',连接状态:' +
              data.is_connect,
          })
        } else {
          showModal()
        }
      },
      fail: (showModal) => showModal(),
      error: () => console.log('error'),
    })
  }

  private onUserIdChange = (event: any) => {
    this.setState({ user_id: event.target.value ? event.target.value : '' })
  }

  private sendMiniLogCmd = () => {
    const { user_id } = this.state
    request({
      config: {
        url: `/logUploadNotice/wechat`,
        method: 'POST',
        data: { user_id },
      },
      success: (resData, showModal) => {
        const { code } = resData
        if (code === 0) {
          message.success('操作成功')
        } else {
          showModal()
        }
      },
      fail: (showModal) => showModal(),
      error: () => console.log('error'),
    })
  }

  private searchMinicLog = () => {
    console.log('searchMincLog:')
    if (!this.state.user_id) {
      message.error('请输入用户id')
      return
    }
    this.setState(
      {
        logCategory: 1,
      },
      () => {
        this.searchLog(`wechat/` + this.state.user_id)
      }
    )
  }

  private searchAdminLog = () => {
    console.log('searchAdminLog:')
    if (!this.state.user_id) {
      message.error('请输入用户id')
      return
    }
    this.setState(
      {
        logCategory: 1,
      },
      () => {
        this.searchLog(`admin/` + this.state.user_id)
      }
    )
  }

  private searchLog = (path: string) => {
    searchCount = 1
    request({
      config: {
        url: `/getLogInfo`,
        method: 'GET',
        params: { device_id: path },
      },
      success: (resData, showModal) => {
        const { code, data } = resData
        if (code === 0) {
          if (data.logs_info) {
            this.sortAndWrap(data.logs_info)
            this.setState({
              logFiles: data.logs_info,
            })
          } else {
            this.setState({
              logFiles: [],
            })
          }
        } else {
          this.setState({
            logFiles: [],
          })
          showModal()
        }
      },
      fail: (showModal) => showModal(),
      error: () => console.log('error'),
    })
  }

  private sortAndWrap(logsInfo: ILogFile[]) {
    if (logsInfo.length === 0) {
      return
    }
    logsInfo.sort((a: ILogFile, b: ILogFile): number => {
      const af = a.file_name.substring(a.file_name.lastIndexOf('/') + 1)
      const bf = b.file_name.substring(b.file_name.lastIndexOf('/') + 1)
      if (af > bf) {
        return -1
      } else if (af === bf) {
        return 0
      } else {
        return 1
      }
    })
    // eslint-disable-next-line array-callback-return
    logsInfo.map((value: ILogFile) => {
      if (value.is_file) {
        // eslint-disable-next-line array-callback-return
        return
      }
      value.children = [
        {
          file_name: Math.random() + 'place_holder/loading...',
          is_file: false,
          size: 0,
          url: 'place_holder',
          create_time: '',
        },
      ]
    })
  }
  private searchChildrenLog = (expanded: boolean, record: ILogFile) => {
    // console.log(expanded)
    // console.log(record)
    if (expanded === false || record.is_file || !record.children) {
      return
    }

    if (
      record.children.length !== 1 ||
      record.children[0].url !== 'place_holder'
    ) {
      return
    }

    const { device_id, user_id, logCategory } = this.state
    const fileName = logCategory === 0 ? device_id : user_id
    searchCount = searchCount + 1
    request({
      config: {
        url: `/getLogInfo`,
        method: 'GET',
        params: { device_id: record.file_name, file_name: fileName },
      },
      success: (resData, showModal) => {
        const { code, data } = resData
        if (code === 0) {
          this.sortAndWrap(data.logs_info)
          record.children = data.logs_info
          this.setState({
            logFiles: Object.assign([], this.state.logFiles),
          })
        } else {
          console.log('code:' + code)
        }
      },
      fail: (showModal) => {
        console.log('error')
      },
      error: () => {
        console.log('error')
      },
    })
  }
}
