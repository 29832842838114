import Axios, { Method } from 'axios'
import { Modal } from 'antd'

export interface ILocation {
  longitude: string
  latitude: string
}

interface IConfig {
  method: Method
  url: string
  baseURL?: string
  headers?: any
  params?: any
  data?: any
  timeout?: number
}

export const cardBodyStyle={
  padding:8
}


export const commonRequest = (props: {
  config: IConfig
  success?: (resData: any, showModal: () => void) => any
  fail?: (showModal: () => void) => any
  error?: () => void
}) => {
  const { config, success, fail, error } = props

  Axios.request(config)
    .then(res => {
      const { status, data: resData } = res

      if (status === 200) {
        const { msg } = resData

        if (success !== undefined) {
          success(resData, () => {
            Modal.error({
              content: msg
            })
          })
        }
      } else {
        if (fail !== undefined) {
          fail(() => {
            Modal.error({
              title: '无法连接服务器',
              content: `请稍后重试`
            })
          })
        }
      }
    })
    .catch(errorMsg => {
      if (error !== undefined) {
        error()
      }

      if (errorMsg.response) {
        if (errorMsg.response.status >= 400) {
          Modal.error({
            title: '无法连接服务器',
            content: `请稍后重试`
          })
        }
      } else {
        console.log('Error', errorMsg.message)
      }
    })
}

export const request = (
  props: {
    config: IConfig
    success?: (resData: any, showModal: () => void) => any
    fail?: (showModal: () => void) => any
    error?: () => void
  }
) => {
  const { config, success, fail, error } = props

  commonRequest({
    config: {
      baseURL: '/log-collect/v1/',
      ...config,
      headers: {
        ...config.headers
      }
    },
    success: (resData, showModal) => {
      if (success !== undefined) {
        success(resData, showModal)
      }
    },
    fail,
    error
  })
}

export interface IIotInfo {
  ip: string
  port: number
  deviceId: string
  key: string
}

export interface IVersion {
  apollo_version: string
  chassis_version: number
  platrom_version: string
}

export interface ITConfig {
  id: string
  location:ILocation
  socketIp:string
  socketPort: number
  mac:string
  enable: boolean
  iotInfo: IIotInfo
  speed: number
  isSaveState: boolean
  versions: IVersion
}
