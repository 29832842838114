import React, { FC, ReactNode, useEffect } from 'react'
import {
  Modal,
  Spin,
  Descriptions,
  Button,
  Space,
  Divider,
  message,
} from 'antd'
import { IDetail } from './index'
import OSS from 'ali-oss'

interface IProps {
  loading: boolean
  visible: boolean
  OTADetail?: IDetail
  OSSClient?: OSS
  refreshOSS: () => void
  hideModal: () => void
}

const EditForm: FC<IProps> = (props) => {
  const {
    visible,
    loading,
    hideModal,
    OTADetail,
    OSSClient,
    refreshOSS,
  } = props

  useEffect(() => {
    if (visible) {
      refreshOSS()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  /**
   * 展示升级方式
   */
  const showModule = (module: string): ReactNode => {
    switch (module) {
      case 'pad':
        return 'Pad'

      case 'master_machine':
        return '上位机'

      case 'chassis':
        return '下位机'

      case 'tight_coupling':
        return '紧组合'

      default:
        return module
    }
  }

  /**
   * 下载升级文件oss
   * @param file 文件key
   */
  const download = (fileKey: string) => () => {
    if (OSSClient) {
      const url = OSSClient.signatureUrl(fileKey)

      window.open(url, '_blank')
    } else {
      message.error('下载失败，请稍后重试')
    }
  }

  return (
    <Modal
      title={`详情`}
      width="900px"
      centered={true}
      visible={visible}
      onCancel={hideModal}
      footer={null}
    >
      <Spin spinning={loading}>
        <Descriptions column={2} bordered={true}>
          <Descriptions.Item label="所属对象" span={2}>
            {OTADetail ? showModule(OTADetail.module) : '-'}
          </Descriptions.Item>

          <Descriptions.Item label="版本号">
            {OTADetail ? OTADetail.version : '-'}
          </Descriptions.Item>

          <Descriptions.Item label="版本序号">
            {OTADetail ? OTADetail.version_code : '-'}
          </Descriptions.Item>

          <Descriptions.Item label="升级包" span={2}>
            {OTADetail && Array.isArray(OTADetail.packets) ? (
              <Space wrap={true} split={<Divider type="vertical" />}>
                {OTADetail.packets.map((item, index) => (
                  <Button
                    size="small"
                    key={index}
                    type="link"
                    onClick={download(item.ali_key)}
                  >
                    {item.ali_key.split('/').pop()}
                  </Button>
                ))}
              </Space>
            ) : (
              '-'
            )}
          </Descriptions.Item>

          {OTADetail &&
            Array.isArray(OTADetail.images) &&
            OTADetail.images.length > 0 && (
              <Descriptions.Item label="docker配置" span={2}>
                {OTADetail.images.map((item, index) => (
                  <div className="OTAVersions-images" key={index}>
                    <p>docker仓库地址：{item.repo}</p>
                    <p>本地仓库地址：{item.repo_local}</p>
                    <p>hash值：{item.image_id}</p>
                  </div>
                ))}
              </Descriptions.Item>
            )}

          <Descriptions.Item label="版本描述" span={2}>
            {OTADetail ? OTADetail.change_log || '-' : '-'}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal>
  )
}

export default EditForm
