import { PureComponent, FormEvent } from 'react'
import { Button, Select, DatePicker } from 'antd'
import { RangeValue } from 'rc-picker/lib/interface'
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form'
import { IParam, IVehicle } from './index'
import moment from 'moment'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

const { Option } = Select
const { RangePicker } = DatePicker

const logApolloModule = [
  'perception',
  'planning',
  'routing',
  'localization',
  'relative_map',
  'prediction',
  'gnss',
  'guardian',
  'monitor',
  'fence',
  'command_handler',
  'msg_transformer',
  'ultanalyse',
  'speedlimit',
  'control',
  'zed_wrapper_node',
]
const logPlatformModule = [
  'alarm_node',
  'monitor_node',
  'data_collect_node',
  'configmodule_node',
  'control_node',
  'data_process_node',
  'communication_module_node',
  'chassis_serial_node',
  'state_machine_node',
]
const logLogModule1 = ['apollo_ros', 'platform_ros']
const logLogModule2 = ['apollo', 'platform', 'chassis', 'system', 'zed']
const logModuleCategory = ['apollo', 'platform']

interface IProps extends FormComponentProps {
  searchLog: (vid: string) => void
  searchLogUploadStatus: (vid: string) => void
  sendVehicleLogCmd: (flag: number, param?: IParam) => void
  vehicles: IVehicle[]
}

interface IState {
  logTypeDisable: boolean
  moduleCategoryDisable: boolean
  moduleDisable: boolean

  logEqu: number
  selectedLogType: string
  selectModuleCategory: string[]
  selectModule: string[]

  vehicle_key: string
  selectDate: string
}

interface IValues {
  time?: [moment.Moment, moment.Moment]
  vehicle_id: string
  service: string
  module: string[]
  type: string
  attribute: string
}

class OpForm extends PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    logTypeDisable: true,
    moduleCategoryDisable: true,
    moduleDisable: false,
    selectedLogType: '',
    logEqu: 0,
    selectModuleCategory: [],
    selectModule: [],

    vehicle_key: '',
    selectDate: '',
  }

  public render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Form layout="inline" title="车辆日志">
        <Form.Item label="车辆">
          {getFieldDecorator('vehicle_id')(
            <Select
              allowClear={true}
              placeholder="请选择车辆"
              style={{ width: 160, margin: 8 }}
              optionFilterProp="children"
              showSearch={true}
              filterOption={this.onVehicleFilter}
              onSelect={this.onVehicleSelect}
            >
              {Array.isArray(this.props.vehicles) &&
                this.props.vehicles.map((item: IVehicle, index: number) => (
                  <Option key={item.device_id} value={item.value}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="时间段">
          {getFieldDecorator('time')(
            <RangePicker onChange={this.onDateSelect} showTime={true} />
          )}
        </Form.Item>
        <Form.Item label="设备">
          {getFieldDecorator('service', { initialValue: 'pad' })(
            <Select
              style={{ width: 160, margin: 8 }}
              placeholder="请选择设备"
              onSelect={this.onEquSelect}
            >
              <Option value="pad">PAD</Option>
              <Option value="vehicle">车辆</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="日志类型">
          {getFieldDecorator('type')(
            <Select
              disabled={this.state.logTypeDisable}
              style={{ width: 120, margin: 8 }}
              placeholder="请选择日志类型"
              onSelect={this.onLogTypeSelect}
            >
              <Option value="coredump">coredump</Option>
              <Option value="ros_bag">ros_bag</Option>
              <Option value="log">log</Option>
              <Option value="boot_up">boot_up</Option>
              <Option value="config">config</Option>
              <Option value="version">version</Option>
              <Option value="gps_bin">gps_bin</Option>
              <Option value="hd_map">hd_map</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="模块类别">
          {getFieldDecorator('attribute')(
            <Select
              disabled={this.state.moduleCategoryDisable}
              style={{ width: 120, margin: 8 }}
              placeholder="请选择模块类别"
              onSelect={this.onModuleCategorySelect}
            >
              {Array.isArray(this.state.selectModuleCategory) &&
                this.state.selectModuleCategory.map(
                  (item: string, index: number) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  )
                )}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="模块">
          {getFieldDecorator('module')(
            <Select
              mode="multiple"
              disabled={this.state.moduleDisable}
              style={{ width: 320, margin: 8 }}
              placeholder="请选择模块"
            >
              {Array.isArray(this.state.selectModule) &&
                this.state.selectModule.map((item: string, index: number) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item className="mr-0">
          <Button
            type="primary"
            style={{ margin: 8 }}
            onClick={this.handleSubmit()}
          >
            下发
          </Button>
          <Button
            type="primary"
            style={{ margin: 8 }}
            onClick={this.cancelLog()}
          >
            取消
          </Button>
          <Button
            type="primary"
            style={{ margin: 8 }}
            onClick={this.searchLog()}
          >
            查询
          </Button>
          <Button
            type="primary"
            style={{ margin: 8 }}
            onClick={this.searchLogUploadStatus()}
          >
            查询车辆上传状态
          </Button>
          *查询只需选择车辆
        </Form.Item>
      </Form>
    )
  }

  private onVehicleFilter(input: string, option: any): boolean {
    return option.props.children
      ? option.props.children
          .toString()
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      : false
  }

  private onVehicleSelect = (svalue: string, opt: any) => {
    this.setState({
      vehicle_key: opt.key ? opt.key.toString() : '',
    })
  }

  private onDateSelect = (dates: RangeValue<moment.Moment>) => {
    this.setState({
      selectDate:
        dates && dates[0] ? moment(dates[0]).format('YYYY-MM-DD') : '',
    })
  }

  private onEquSelect = (svalue: string) => {
    this.props.form.resetFields(['type', 'attribute', 'module'])
    if (svalue === 'pad') {
      this.setState({
        logTypeDisable: true,
        moduleCategoryDisable: true,
        moduleDisable: false,
        logEqu: 0,
        selectModule: ['all'],
      })
    } else if (svalue === 'vehicle') {
      this.setState({
        logTypeDisable: false,
        moduleCategoryDisable: false,
        moduleDisable: false,
        logEqu: 1,
        selectModuleCategory: [],
        selectModule: [],
      })
    }
  }

  private onLogTypeSelect = (svalue: string) => {
    this.props.form.resetFields(['attribute', 'module'])

    this.setState({
      selectedLogType: svalue,
      selectModule: [],
    })
    if (svalue === 'coredump') {
      this.setState({
        selectModuleCategory: logModuleCategory,
      })
    } else {
      this.setState({
        selectModuleCategory: ['all'],
      })
      // if (svalue === "log") {
      //   this.setState({
      //     selectModule: [...logLogModule1, ...logApolloModule, ...logPlatformModule, ...logLogModule2]
      //   })
      // }
    }
  }
  private onModuleCategorySelect = (svalue: string) => {
    this.props.form.resetFields(['module'])
    if (this.state.selectedLogType === 'coredump') {
      if (svalue === 'apollo') {
        this.setState({
          selectModule: logApolloModule,
        })
      } else if (svalue === 'platform') {
        this.setState({
          selectModule: logPlatformModule,
        })
      }
    } else if (this.state.selectedLogType === 'log') {
      this.setState({
        selectModule: [
          ...logLogModule1,
          ...logApolloModule,
          ...logPlatformModule,
          ...logLogModule2,
        ],
      })
    } else {
      this.setState({
        selectModule: ['all'],
      })
    }
  }

  private handleSubmit = () => (e: FormEvent) => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values: IValues) => {
      if (!err) {
        const { time, vehicle_id, service, module, attribute, type } = values
        const params = {
          vehicle_id,
          service,
          module,
          type,
          attribute,
          start_time:
            time && time[0]
              ? moment(time[0]).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
          end_time:
            time && time[1]
              ? moment(time[1]).format('YYYY-MM-DD HH:mm:ss')
              : undefined,
        }
        if (params.service === 'pad') {
          params.module = ['all']
        }
        this.props.sendVehicleLogCmd(this.state.logEqu, params)
      }
    })
  }

  private cancelLog = () => (e: FormEvent) => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values: IValues) => {
      if (!err) {
        const { vehicle_id, service } = values
        const params = {
          vehicle_id,
          service,
          status: 'stop',
        }
        this.props.sendVehicleLogCmd(this.state.logEqu, params)
      }
    })
  }

  private searchLog = () => (e: FormEvent) => {
    e.preventDefault()
    //清空日期
    // this.props.form.validateFieldsAndScroll((err, values: IValues) => {
    //   if (!err) {
    //     this.props.searchLog(
    //       this.state.vehicle_key +
    //         (this.state.selectDate ? '/' + this.state.selectDate : '')
    //     )
    //   }
    // })
    
    this.props.form.validateFieldsAndScroll((err, values: IValues) => {
      if (!err) {
        this.props.searchLog(this.state.vehicle_key)
      }
    })
  }

  private searchLogUploadStatus = () => (e: FormEvent) => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values: IValues) => {
      if (!err) {
        this.props.searchLogUploadStatus(values.vehicle_id)
      }
    })
  }
}

const WrappedOpForm = Form.create<IProps>()(OpForm)

export default WrappedOpForm
