import React, { FC, useState } from 'react'
import { Layout, Spin, Table, Space, Button, Modal, message } from 'antd'
import {
  useRequestWithJWT,
  headersWithJWT,
  buildUrl,
  TOTAModule,
} from '../../../utils'
import { getTableColumns } from './tableColumns'
import moment from 'moment'
import SearchForm, { IValues } from './SearchForm'
import EditForm, { IImages, IPacket } from './EditForm'
import DetailModal from './DetailModal'
import OSS from 'ali-oss'

import './index.less'

const { Content } = Layout

export interface IDetail {
  change_log: string
  module: TOTAModule
  version: string
  version_code: number
  images: IImages[]
  packets: IPacket[]
}

interface IParams {
  version?: string
  module: string
  start_time?: string
  end_time?: string
  page: number
  page_size: number
}

const OTAVersions: FC = () => {
  const [params, setParams] = useState<IParams>({
    module: 'all',
    page: 1,
    page_size: 10,
  })
  const [id, setId] = useState<string>()
  const [OTADetail, setOTADetail] = useState<IDetail>()
  const [tableTotal, setTableTotal] = useState<number>(0)
  const [tableDate, setTableDate] = useState<any[]>([])
  const [isShowEditForm, setIsShowEditForm] = useState<boolean>(false)
  const [isShowDetailModal, setIsShowDetailModal] = useState<boolean>(false)
  const [OSSClient, setOSSClient] = useState<OSS>()

  /**
   * 获取版本列表
   */
  const getOTAVersions = useRequestWithJWT({
    service: {
      url: buildUrl(`/tool/v1/ota/versions`, params),
      method: 'GET',
      headers: headersWithJWT(),
    },
    option: {
      manual: false,
      refreshDeps: [params],
    },
    onSuccess: (res) => {
      const { data, total } = res || {}

      setTableTotal(total || 0)
      setTableDate(data || [])
    },
  })

  /**
   * 刷新列表并清空缓存id
   */
  const refreshOTAVersions = () => {
    setId(undefined)
    getOTAVersions.refresh()
  }

  /**
   * 刷新列表并清空缓存id
   */
  const refreshOSS = () => {
    getOssData.refresh()
  }

  /**
   * 获取版本详情
   */
  const getOTADetail = useRequestWithJWT({
    service: (id: string) => ({
      url: `/tool/v1/ota/versions/${id}`,
      method: 'GET',
      headers: headersWithJWT(),
    }),
    onSuccess: (res) => {
      const { data } = res || {}

      setOTADetail(data)
    },
  })

  /**
   * 删除Version
   */
  const deleteVersion = useRequestWithJWT({
    service: (id: string) => ({
      url: `/tool/v1/ota/versions/${id}`,
      method: 'DELETE',
      headers: headersWithJWT(),
    }),
    onSuccess: () => {
      message.success('删除成功')

      getOTAVersions.refresh()
    },
  })

  /**
   * 获取OSS Token
   */
  const getOssData = useRequestWithJWT({
    service: {
      url: `/tool/v1/getOssToken`,
      method: 'GET',
      headers: headersWithJWT(),
    },
    option: {
      manual: false,
      cacheKey: 'ossToken',
      cacheTime: 1200000,
    },
    onSuccess: (res) => {
      const { data } = res || {}
      const { access_key, secret_key, token } = data || {}

      setOSSClient(
        new OSS({
          region: 'oss-cn-hangzhou',
          secure: true,
          bucket: 'goldenridge-pkg',
          accessKeyId: access_key,
          accessKeySecret: secret_key,
          stsToken: token,
        })
      )
    },
  })

  /**
   * 点击查询按钮触发
   */
  const onSubmit = (values: IValues) => {
    const { version, module, time } = values

    setParams((params) => ({
      ...params,
      version,
      module,
      page: 1,
      start_time:
        time && time[0] ? moment(time[0]).format('YYYY-MM-DD') : undefined,
      end_time:
        time && time[1] ? moment(time[1]).format('YYYY-MM-DD') : undefined,
    }))
  }

  /**
   * 点击删除按钮触发
   */
  const onDelete = (id: string) => () => {
    Modal.confirm({
      title: '确定要删除该版本吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        deleteVersion.run(id)
      },
    })
  }

  /**
   * 根据id判断是否调接口获取详情数据
   * @param id id
   */
  const getDetail = (id?: string) => {
    if (!id) {
      setOTADetail(undefined)
    } else {
      getOTADetail.run(id)
    }
  }

  /**
   * 点击详情按钮触发
   */
  const showVersionDetail = (id: string) => () => {
    getDetail(id)
    setId(id)
    setIsShowDetailModal(true)
  }

  /**
   * 点击新增按钮触发
   */
  const showEditForm = (id?: string) => () => {
    getDetail(id)
    setId(id)
    setIsShowEditForm(true)
  }

  /**
   * 翻页
   */
  const onPaginationchange = (page: number) => {
    setParams((params) => ({ ...params, page }))
  }

  return (
    <Spin spinning={getOTAVersions.loading}>
      <Content className="app_main">
        <Space
          className="app_content OTAVersions"
          direction="vertical"
          size="middle"
        >
          <SearchForm onSubmit={onSubmit} />

          <Button type="primary" onClick={showEditForm()}>
            新增
          </Button>

          <Table
            className="app_main_table"
            rowKey="id"
            locale={{ emptyText: '暂无数据' }}
            columns={getTableColumns(showEditForm, onDelete, showVersionDetail)}
            dataSource={tableDate}
            pagination={{
              current: params.page,
              total: tableTotal,
              onChange: onPaginationchange,
              showSizeChanger: false,
            }}
          />

          <EditForm
            id={id}
            loading={getOTADetail.loading}
            visible={isShowEditForm}
            OTADetail={OTADetail}
            OSSClient={OSSClient}
            refreshOSS={refreshOSS}
            hideModal={() => setIsShowEditForm(false)}
            refreshOTAVersions={refreshOTAVersions}
          />

          <DetailModal
            loading={getOTADetail.loading}
            visible={isShowDetailModal}
            OTADetail={OTADetail}
            OSSClient={OSSClient}
            refreshOSS={refreshOSS}
            hideModal={() => setIsShowDetailModal(false)}
          />
        </Space>
      </Content>
    </Spin>
  )
}

export default OTAVersions
