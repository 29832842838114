import React, { FC } from 'react'
import { Layout, Avatar, Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  EditOutlined,
  LogoutOutlined,
} from '@ant-design/icons'

import './index.less'

const { Header } = Layout
const { Item: MenuItem } = Menu

interface IProps {
  userName: string | null
  collapsed: boolean
  toggleSider: (boolean: boolean) => void
  logout: () => void
  modifyPassword: () => void
}

const HeaderCompontent: FC<IProps> = (props) => {
  const { userName, collapsed, toggleSider, logout, modifyPassword } = props

  return (
    <Header className="flex-space_between ph-20 appHeader">
      {collapsed ? (
        <MenuUnfoldOutlined
          className="appHeader_trigger"
          onClick={() => toggleSider(false)}
        />
      ) : (
        <MenuFoldOutlined
          className="appHeader_trigger ml-120"
          onClick={() => toggleSider(true)}
        />
      )}

      <Link to="/">
        <h2 className="mv-0 mh-20">金瑞麒工具管理平台</h2>
      </Link>

      <Dropdown
        overlay={
          <Menu>
            <MenuItem key="resetPassword" onClick={modifyPassword}>
              <EditOutlined className="mr-10" />
              修改密码
            </MenuItem>

            <MenuItem key="logout" onClick={logout}>
              <LogoutOutlined className="mr-10" />
              退出登录
            </MenuItem>
          </Menu>
        }
      >
        <div className="flex-center appHeader_userInfo">
          <div className="mr-10">{userName}</div>
          <Avatar size="large">
            {userName && userName.substring(0, 1).toLocaleUpperCase()}
          </Avatar>
        </div>
      </Dropdown>
    </Header>
  )
}

export default HeaderCompontent
