import { Button, Space } from 'antd'

export const getTableColumns = (
  editVersion: (id: string) => any,
  deleteVersion: (id: string) => any,
  showVersionDetail: (id: string) => any
) => [
  {
    title: '版本号',
    dataIndex: 'version_name',
    key: 'version_name',
  },
  {
    title: '版本序号',
    dataIndex: 'version_code',
    key: 'version_code',
  },
  {
    title: '所属对象',
    dataIndex: 'module',
    key: 'module',
  },
  {
    title: '新增时间',
    dataIndex: 'add_time',
    key: 'add_time',
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
    key: 'update_time',
  },
  {
    title: '操作',
    key: 'action',
    render: (item: { id: string }) => (
      <Space>
        <Button type="primary" size="small" onClick={editVersion(item.id)}>
          编辑
        </Button>

        <Button
          type="primary"
          danger={true}
          size="small"
          onClick={deleteVersion(item.id)}
        >
          删除
        </Button>

        <Button
          type="primary"
          size="small"
          onClick={showVersionDetail(item.id)}
        >
          详情
        </Button>
      </Space>
    ),
  },
]
