import React, { FC } from 'react'
import { Layout, Result } from 'antd'

const { Content } = Layout

const NoMatch: FC = () => {
  return (
    <Content className="app_main">
      <div className="app_content flex-center">
        <Result
          status="404"
          title="404"
          subTitle="对不起，没有找到该页面，请检查地址是否正确！"
        />
      </div>
    </Content>
  )
}

export default NoMatch
