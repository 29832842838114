import { FC, useState } from 'react'
import { Layout, Table, Spin, message, Modal, Space } from 'antd'
import { getTableColumns } from './tableColumns'
import SearchForm, { IParams } from './SearchForm'
import { useRequestWithJWT, headersWithJWT, buildUrl } from '../../utils'

const { Content } = Layout

const RouteCollect: FC = () => {
  const [params, setParams] = useState<IParams>({
    page: 1,
    page_size: 10,
  })
  const [tableTotal, setTableTotal] = useState<number>(0)
  const [tableData, setTableData] = useState<any[]>([])

  /**
   * 获取采集路线列表
   */
  const getRouteCollectList = useRequestWithJWT({
    service: {
      url: buildUrl(`/tool/v1/routeCollect`, params),
      method: 'GET',
      headers: headersWithJWT(),
    },
    option: {
      manual: false,
      refreshDeps: [params],
    },
    onSuccess: (res) => {
      const { data, total } = res || {}

      setTableTotal(total || 0)
      setTableData(data || [])
    },
  })

  /**
   * 删除采集路线
   */
  const deleteRouteCollect = useRequestWithJWT({
    service: (id: string) => ({
      url: buildUrl(`/tool/v1/routeCollect/deleteCollectRoute`, { id }),
      method: 'DELETE',
      headers: headersWithJWT(),
    }),
    onSuccess: () => {
      getRouteCollectList.refresh()

      message.success('删除成功')
    },
  })

  /**
   * 点击查询按钮触发
   */
  const onSubmit = (values: IParams) => {
    const { description } = values

    setParams((params) => ({
      ...params,
      page: 1,
      description,
    }))
  }

  /**
   * 翻页
   */
  const onPaginationchange = (page: number) => {
    setParams((params) => ({ ...params, page }))
  }

  /**
   * 点击删除按钮触发
   */
  const onDelete = (id: string) => () => {
    Modal.confirm({
      title: '确认删除该路线吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        deleteRouteCollect.run(id)
      },
    })
  }

  return (
    <Spin spinning={getRouteCollectList.loading || deleteRouteCollect.loading}>
      <Content className="app_main">
        <Space className="app_content" direction="vertical" size="middle">
          <SearchForm onSubmit={onSubmit} />

          <Table
            className="app_main_table"
            rowKey="id"
            locale={{ emptyText: '暂无数据' }}
            columns={getTableColumns(onDelete)}
            dataSource={tableData}
            pagination={{
              current: params.page,
              total: tableTotal,
              onChange: onPaginationchange,
              showSizeChanger: false,
            }}
          />
        </Space>
      </Content>
    </Spin>
  )
}

export default RouteCollect
