import { FC, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb, Layout, Spin, Row, Col, Divider, message } from 'antd'
import gcoord from 'gcoord'
import OSS from 'ali-oss'
import { AMapScene, LineLayer } from '@antv/l7-react'
import { useRequestWithJWT, headersWithJWT, buildUrl } from '../../utils'
import useRequest from '@ahooksjs/use-request'

import './index.less'

const { Content } = Layout

interface IParams {
  id: string
}

interface IMapData {
  // 红色|绿色|黄色
  color: '#D62A5E' | '#26DDA6' | '#EFB300'
  latitude: number
  longitude: number
  latitude1: number
  longitude1: number
}

interface IMapJsonData {
  aglSlnstate: number
  locSlnstate: number
  latitude: number
  longitude: number
  unavailable: boolean
  id: number
}

interface IDetail {
  id: string
  can_auto: number
  create_time: string
  description: string
  fixed_solution_rate: number
  mileage: string
  oss_file_key: string
}

const RouteCollectDetails: FC = () => {
  const [details, setDetails] = useState<IDetail>()
  const [mapData, setMapData] = useState<IMapData[]>([])
  const [fileKey, setFileKey] = useState<string>()
  const [isMapLoading, setIsMapLoading] = useState<boolean>(true)

  const { id } = useParams<IParams>()

  /**
   * 获取采集路线详情
   */
  const getRouteDetails = useRequestWithJWT({
    service: {
      url: buildUrl(`/tool/v1/routeCollect/getRouteCollect`, { id }),
      method: 'GET',
      headers: headersWithJWT(),
    },
    option: {
      manual: false,
    },
    onSuccess: (res) => {
      const { data } = res || {}
      const { oss_file_key } = data || {}

      setDetails(data)
      setFileKey(oss_file_key)
    },
  })

  /**
   * 获取路线Json文件
   */
  const getJson = useRequest(
    (url: string) => ({
      url,
      method: 'GET',
    }),
    {
      manual: true,
      onSuccess: (mapJson: IMapJsonData[]) => {
        const mapJsonData: IMapJsonData[] = []

        if (Array.isArray(mapJson) && mapJson.length > 2) {
          const jsonLen = mapJson.length
          const setJson = (i: number) => {
            const element = mapJson[i]

            const arr = gcoord.transform(
              [element.longitude, element.latitude],
              gcoord.WGS84,
              gcoord.GCJ02
            )

            mapJsonData.push({
              ...element,
              longitude: arr[0],
              latitude: arr[1],
            })
          }

          if (jsonLen > 10) {
            for (let i = 0; i < jsonLen; i += 2) {
              setJson(i)
            }
          } else {
            for (let i = 0; i < jsonLen; i++) {
              setJson(i)
            }
          }
        }

        const mapData =
          Array.isArray(mapJsonData) && mapJsonData.length > 1
            ? mapJsonData.map((item: IMapJsonData, i: number) => {
                if (i > 0) {
                  return {
                    color: mapJsonData[i - 1]['unavailable']
                      ? '#EFB300'
                      : item.aglSlnstate === 50 && item.locSlnstate === 4
                      ? '#26DDA6'
                      : '#D62A5E',
                    latitude: mapJsonData[i - 1]['latitude'],
                    longitude: mapJsonData[i - 1]['longitude'],
                    latitude1: item.latitude,
                    longitude1: item.longitude,
                  }
                } else {
                  return undefined
                }
              })
            : []

        mapData.shift()

        setMapData(mapData as IMapData[])

        setIsMapLoading(false)
      },
      onError: (err) => {
        console.log(err)

        setIsMapLoading(false)
        message.error('Json下载失败，请刷新页面重试', 5)
      },
    }
  )

  /**
   * 获取OSS Token，然后获取路线json的url
   */
  const getJsonUrl = useRequestWithJWT({
    service: {
      url: `/tool/v1/getOssToken`,
      method: 'GET',
      headers: headersWithJWT(),
    },
    onSuccess: (res) => {
      const { data } = res || {}
      const { access_key, secret_key, token } = data || {}

      const OSSClient = new OSS({
        region: 'oss-cn-hangzhou',
        secure: true,
        bucket: 'tourist-source',
        accessKeyId: access_key,
        accessKeySecret: secret_key,
        stsToken: token,
      })

      if (fileKey) {
        const url = OSSClient.signatureUrl(fileKey)
        getJson.run(url)
      }
    },
  })

  useEffect(() => {
    if (fileKey) {
      getJsonUrl.run()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileKey])

  return (
    <Spin spinning={getRouteDetails.loading}>
      <Content className="app_main hasBreadcrumb">
        <Breadcrumb className="hasBreadcrumb_breadcrumb">
          <Breadcrumb.Item>
            <Link to={`/routeCollect`}>路线采集管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>路线采集详情</Breadcrumb.Item>
        </Breadcrumb>

        <div className="app_content routeCollectDetails">
          <Row justify="space-between">
            <Col span={8}>
              路线ID：
              {details && details.id ? details.id : '-'}
            </Col>
            <Col span={8}>路线里程：{(details && details.mileage) || '-'}</Col>
            <Col span={8}>
              创建时间：
              {(details && details.create_time) || '-'}
            </Col>
          </Row>

          <Divider />

          <div>路线描述：{(details && details.description) || '-'}</div>

          <Divider />

          <Row justify="space-between">
            <Col span={12}>
              固定解占比：
              {details && typeof details.fixed_solution_rate === 'number'
                ? `${details.mileage}%`
                : '-'}
            </Col>
            <Col span={12}>
              {details && details.can_auto
                ? '可参考作为自动驾驶路线'
                : '不完全满足自动驾驶路线条件'}
            </Col>
          </Row>
          <div></div>

          <Spin spinning={isMapLoading}>
            <div className="mt-16 routeCollectDetails_map">
              <AMapScene
                option={{ logoVisible: false }}
                map={{
                  center: [105, 34],
                  style: 'light',
                  pitch: 0,
                  zoom: 3.6,
                  isHotspot: false,
                  doubleClickZoom: false,
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <LineLayer
                  key={'2'}
                  options={{
                    autoFit: true,
                  }}
                  source={{
                    data: mapData,
                    parser: {
                      type: 'json',
                      x: 'longitude',
                      y: 'latitude',
                      x1: 'longitude1',
                      y1: 'latitude1',
                    },
                  }}
                  color={{
                    field: 'color',
                    values: (color) => color,
                  }}
                  shape={{
                    values: 'line',
                  }}
                  style={{
                    opacity: 1,
                  }}
                />
              </AMapScene>
            </div>
          </Spin>
        </div>
      </Content>
    </Spin>
  )
}

export default RouteCollectDetails
