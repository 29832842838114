import React, { FC } from 'react'
import { Form, Select, DatePicker, Button, Space, Input } from 'antd'

const { Option } = Select
const { RangePicker } = DatePicker

export interface IValues {
  version?: string
  module: string
  time?: [moment.Moment, moment.Moment]
}

interface IPorps {
  onSubmit: (values: IValues) => void
}

const OTAVersionsForm: FC<IPorps> = (props) => {
  const [form] = Form.useForm()

  /**
   * 提交表单
   */
  const onFinish = (values: IValues) => {
    props.onSubmit(values)
  }

  /**
   * 重置表单
   */
  const onReset = () => {
    form.resetFields()
  }

  return (
    <Form
      layout="inline"
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      requiredMark={false}
    >
      <Space className="flex-1" align="end" wrap={true}>
        <Form.Item
          label="版本号"
          name="version"
          getValueFromEvent={(event) =>
            event.target.value.replace(/[^\x21-\x7e]/g, '')
          }
        >
          <Input maxLength={40} placeholder="用户名" />
        </Form.Item>

        <Form.Item
          label="所属对象"
          name="module"
          initialValue="all"
          rules={[{ required: true, message: '所属对象不能为空' }]}
        >
          <Select placeholder="请选择所属对象" style={{ width: '174px' }}>
            <Option key="all" value="all">
              全部
            </Option>

            <Option key="pad" value="pad">
              Pad
            </Option>

            <Option key="master_machine" value="master_machine">
              上位机
            </Option>

            <Option key="chassis" value="chassis">
              下位机
            </Option>

            <Option key="tight_coupling" value="tight_coupling">
              紧组合
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label="新增时间" name="time">
          <RangePicker showTime={false} />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit">
            查询
          </Button>

          <Button type="default" htmlType="reset">
            重置
          </Button>
        </Space>
      </Space>
    </Form>
  )
}

export default OTAVersionsForm
