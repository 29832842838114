import React, { FC, useState } from 'react'
import { Form, Spin, Select, DatePicker, Button, Space } from 'antd'
import { useRequestWithJWT, headersWithJWT, buildUrl } from '../../../utils'

const { Option } = Select
const { RangePicker } = DatePicker

export interface IValues {
  site_ids?: string[]
  vehicle_ids?: string[]
  status: string
  time?: [moment.Moment, moment.Moment]
}

interface ISites {
  id: string
  name: string
}

interface ICars {
  id: string
  number: string
}

interface IPorps {
  onSubmit: (values: IValues) => void
}

const SearchForm: FC<IPorps> = (props) => {
  const [form] = Form.useForm()
  const [sites, setSites] = useState<ISites[]>([])
  const [cars, setCars] = useState<ICars[]>([])

  /**
   * 根据景区名获取景区下拉框
   */
  const fetchSite = useRequestWithJWT({
    service: (params) => ({
      url: buildUrl('/tool/v1/sites', params),
      method: 'GET',
      headers: headersWithJWT(),
    }),
    onSuccess: (res) => {
      const { data } = res || {}

      if (Array.isArray(data)) {
        setSites(data)
      } else {
        setSites([])
      }
    },
  })

  /**
   * 根据车牌号获取车辆下拉框
   */
  const fetchCar = useRequestWithJWT({
    service: (params) => ({
      url: buildUrl('/tool/v1/vehicles', params),
      method: 'GET',
      headers: headersWithJWT(),
    }),
    onSuccess: (res) => {
      const { data } = res || {}

      if (Array.isArray(data)) {
        setCars(data)
      } else {
        setCars([])
      }
    },
  })

  /**
   * 景区名称改变时触发
   * @param value 景区名称
   */
  const onSiteSearch = (value: string) => {
    if (value) {
      fetchSite.run({ name: value })
    } else {
      setSites([])
    }
  }

  /**
   * 车牌号改变时触发
   * @param value 车牌号
   */
  const onCarSearch = (value: string) => {
    if (value) {
      fetchCar.run({ number: value })
    } else {
      setCars([])
    }
  }

  /**
   * 提交表单
   */
  const onFinish = (values: IValues) => {
    props.onSubmit(values)
  }

  /**
   * 重置表单
   */
  const onReset = () => {
    form.resetFields()
  }

  return (
    <Form
      layout="inline"
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      requiredMark={false}
    >
      <Space className="flex-1" align="end" wrap={true}>
        <Form.Item label="景区名称" name="site_ids">
          <Select
            mode="multiple"
            placeholder="请输入景区名称"
            style={{ width: '174px' }}
            showSearch={true}
            showArrow={false}
            allowClear={true}
            notFoundContent={fetchSite.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSiteSearch}
          >
            {Array.isArray(sites) &&
              sites.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="车牌号" name="vehicle_ids">
          <Select
            mode="multiple"
            placeholder="请输入车牌号"
            style={{ width: '174px' }}
            showSearch={true}
            showArrow={false}
            allowClear={true}
            notFoundContent={fetchCar.loading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onCarSearch}
          >
            {Array.isArray(cars) &&
              cars.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.number}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="升级状态"
          name="status"
          initialValue="failed"
          rules={[{ required: true, message: '升级状态不能为空' }]}
        >
          <Select placeholder="请选择升级状态" style={{ width: '174px' }}>
            <Option key="not_started" value="not_started">
              未开始
            </Option>

            <Option key="failed" value="failed">
              已失败
            </Option>

            <Option key="in_progress" value="in_progress">
              进行中
            </Option>

            <Option key="success" value="success">
              已完成
            </Option>

            <Option key="timed_out" value="timed_out">
              已超时
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label="添加时间" name="time">
          <RangePicker showTime={false} />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit">
            查询
          </Button>

          <Button type="default" htmlType="reset">
            重置
          </Button>
        </Space>
      </Space>
    </Form>
  )
}

export default SearchForm
