import React, { FC, useState, useEffect } from 'react'
import { Layout } from 'antd'
import {
  Route,
  withRouter,
  Redirect,
  useLocation,
  useHistory,
  Switch,
} from 'react-router-dom'
import ModifyPasswordFrom from './ModifyPasswordForm'

import Header from '../Components/Header'
import Sider from '../Components/Sider'

import './index.less'

import OTAProgresses from '../Containers/OTA/OTAProgresses'
import OTATasks from '../Containers/OTA/OTATasks'
import OTAVersions from '../Containers/OTA/OTAVersions'
import LogManage from '../Containers/LogManage'
import RouteCollect from '../Containers/RouteCollect'
import RouteCollectDetails from '../Containers/RouteCollect/Detail'
import NoMatch from '../Containers/404'

const App: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const name = localStorage.getItem('name')
  const jwt = localStorage.getItem('jwt')
  const isLogined = name != null && jwt != null

  const [menuSelectedKeys, setMenuSelectedKeys] = useState<string>(
    pathname.split('/')[1] || 'OTAProgresses'
  )
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [modifyPasswordVisible, setModifyPasswordVisible] =
    useState<boolean>(false)

  const userName = localStorage.getItem('name')

  /**
   * 隐藏展示侧边菜单
   */
  const toggleSider = (boolean: boolean): void => {
    setCollapsed(boolean)
  }

  /**
   * 退出登录
   */
  const logout = (): void => {
    localStorage.clear()
    history.replace('/Login')
  }

  /**
   * 展示修改密码Modal
   */
  const showModifyPasswordModal = () => {
    setModifyPasswordVisible(true)
  }

  /**
   * 隐藏修改密码Modal
   */
  const hideModifyPasswordModal = () => {
    setModifyPasswordVisible(false)
  }

  /**
   * 根据url判断当前menu位置
   */
  useEffect(() => {
    window.scrollTo(0, 0)

    setMenuSelectedKeys(pathname.split('/')[1] || 'OTAProgresses')
  }, [pathname])

  return !isLogined ? (
    <Redirect to="/Login" />
  ) : (
    <Layout className="app">
      <Sider
        menuSelectedKeys={menuSelectedKeys}
        collapsed={collapsed}
        onBreakpoint={toggleSider}
      />

      <Layout className={`app_container ${collapsed ? 'ml-80' : 'ml-200'}`}>
        <Header
          userName={userName}
          collapsed={collapsed}
          toggleSider={toggleSider}
          logout={logout}
          modifyPassword={showModifyPasswordModal}
        />

        <ModifyPasswordFrom
          visible={modifyPasswordVisible}
          hideModal={hideModifyPasswordModal}
          logout={logout}
        />

        <Switch>
          <Route exact={true} path="/">
            <OTAProgresses />
          </Route>

          <Route exact={true} path="/OTAProgresses">
            <OTAProgresses />
          </Route>

          <Route exact={true} path="/OTATasks">
            <OTATasks />
          </Route>

          <Route exact={true} path="/OTAVersions">
            <OTAVersions />
          </Route>

          <Route exact={true} path="/RouteCollect">
            <RouteCollect />
          </Route>

          <Route path="/RouteCollect/RouteCollectDetails/:id">
            <RouteCollectDetails />
          </Route>

          <Route exact={true} path="/LogManage">
            <LogManage />
          </Route>

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Layout>
    </Layout>
  )
}

export default withRouter(App)
