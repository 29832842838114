import React, { FC } from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import {
  UploadOutlined,
  ContainerOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons'

import './index.less'

import logo from '../../images/logo_white.svg'
import icon_logo from '../../images/icon_logo.svg'

const { Sider } = Layout
const { Item: MenuItem, SubMenu } = Menu

interface IProps {
  menuSelectedKeys: string
  collapsed: boolean
  onBreakpoint: (collapsed: boolean) => void
}

const getOpenKeys = (menuSelectedKeys: string): string[] => {
  if (menuSelectedKeys.indexOf('OTA') > -1) {
    return ['OTA']
  }

  return []
}

const SiderCompontent: FC<IProps> = (props) => {
  const { menuSelectedKeys, collapsed, onBreakpoint } = props

  return (
    <Sider
      className={`appSider ${collapsed ? '' : 'open'}`}
      breakpoint="xxl"
      collapsedWidth={80}
      collapsible={true}
      collapsed={collapsed}
      onBreakpoint={onBreakpoint}
      trigger={null}
    >
      <div>
        <Link to="/">
          <div className="appSider_logo">
            <img src={collapsed ? icon_logo : logo} alt="logo" />
          </div>
        </Link>

        <Menu
          className="appSider_nav"
          mode="inline"
          theme="dark"
          selectedKeys={[menuSelectedKeys]}
          defaultOpenKeys={getOpenKeys(menuSelectedKeys)}
        >
          <SubMenu
            key="OTA"
            title={
              <span>
                <UploadOutlined />
                <span>OTA升级</span>
              </span>
            }
          >
            <MenuItem key="OTAProgresses">
              <Link to="/OTAProgresses">升级进度列表</Link>
            </MenuItem>

            <MenuItem key="OTATasks">
              <Link to="/OTATasks">升级任务配置</Link>
            </MenuItem>

            <MenuItem key="OTAVersions">
              <Link to="/OTAVersions">历史版本管理</Link>
            </MenuItem>
          </SubMenu>

          <MenuItem key="RouteCollect">
            <Link to="/RouteCollect">
              <NodeIndexOutlined />
              <span>路线采集管理</span>
            </Link>
          </MenuItem>

          <MenuItem key="LogManage">
            <Link to="/LogManage">
              <ContainerOutlined />
              <span>日志管理</span>
            </Link>
          </MenuItem>
        </Menu>
      </div>

      <div className="appSider_version">
        {process.env.REACT_APP_VERSION || ''}
      </div>
    </Sider>
  )
}

export default SiderCompontent
