import React, {
  FC,
  useState,
  Key,
  Dispatch,
  SetStateAction,
  ReactText,
} from 'react'
import { Tree, Input, Space, Spin } from 'antd'
import { DataNode } from 'antd/lib/tree'
import { SearchOutlined } from '@ant-design/icons'
import { useRequestWithJWT, headersWithJWT } from '../../../utils'

interface IProps {
  setCheckedKeys: Dispatch<SetStateAction<ReactText[]>>
}

interface ITreeData {
  parent_id: string
  title: string
  key: string
  is_vehicle: boolean
  children?: ITreeData[]
}

const CarTree: FC<IProps> = (props) => {
  const [treeData, setTreeData] = useState<ITreeData[]>([
    {
      title: '全部景区',
      key: '0',
      parent_id: '0',
      is_vehicle: false,
      children: [],
    },
  ])
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')

  /**
   * 获取景区车辆树
   */
  const { loading } = useRequestWithJWT({
    service: {
      url: `/tool/v1/ota/task/vehicle_tree`,
      method: 'GET',
      headers: headersWithJWT(),
    },
    option: {
      manual: false,
    },
    onSuccess: (res) => {
      const { data } = res || {}
      if (Array.isArray(data)) {
        setTreeData([
          {
            title: '全部景区',
            key: '0',
            parent_id: '0',
            is_vehicle: false,
            children: data,
          },
        ])
      }
    },
  })

  /**
   * 根据输入值搜索过滤tree
   */
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const expandedKeys: Key[] = []

    const getParentKey = (tree: ITreeData[]) => {
      for (const item of tree) {
        if (item.children) {
          getParentKey(item.children)
        }

        if (item.title.indexOf(value) > -1) {
          expandedKeys.push(item.parent_id)
        }
      }
    }

    if (value !== '') {
      getParentKey(treeData)
    }

    setExpandedKeys(expandedKeys)
    setSearchValue(value)
    setAutoExpandParent(true)
  }

  /**
   * 展开tree
   */
  const onExpand = (expandedKeys: Key[]) => {
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }

  /**
   * 根据数据渲染tree
   * @param data 数据源
   */
  const loop = (data: ITreeData[]): DataNode[] =>
    data.map((item) => {
      const index = item.title.indexOf(searchValue)
      const title =
        index > -1 ? (
          <span>
            {item.title.substr(0, index)}
            <span style={{ color: '#f50' }}>{searchValue}</span>
            {item.title.substr(index + searchValue.length)}
          </span>
        ) : (
          <span>{item.title}</span>
        )

      if (item.children) {
        return {
          title,
          key: item.key,
          children: loop(item.children),
          isCar: item.is_vehicle,
        }
      }

      return {
        title,
        key: item.key,
        isCar: item.is_vehicle,
      }
    })

  /**
   *  选择tree时过滤车辆id
   */
  const onCheck = (
    checked:
      | {
          checked: Key[]
          halfChecked: Key[]
        }
      | Key[],
    info: {
      checkedNodes: DataNode[]
    }
  ) => {
    const { checkedNodes } = info

    const checkedCarNodes = checkedNodes.filter((item: any) => item.isCar)

    const checkedCarKeys = checkedCarNodes.map((item) => item.key)

    props.setCheckedKeys(checkedCarKeys)
  }

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" className="OTATasks-left ">
        <Input
          placeholder="请输入车牌号"
          onChange={onSearchChange}
          suffix={<SearchOutlined />}
        />

        <Tree
          checkable={true}
          blockNode={true}
          showLine={{ showLeafIcon: false }}
          selectable={false}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          treeData={loop(treeData)}
          onCheck={onCheck}
        />
      </Space>
    </Spin>
  )
}

export default CarTree
