import { FC } from 'react'
import { Form, Input, Button, Space } from 'antd'

export interface IParams {
  page?: number
  page_size?: number
  description?: string
}

interface IPorps {
  onSubmit: (values: IParams) => void
}

const SearchForm: FC<IPorps> = (props) => {
  const [form] = Form.useForm()

  /**
   * 提交表单
   */
  const onFinish = (values: IParams) => {
    props.onSubmit(values)
  }

  /**
   * 重置表单
   */
  const onReset = () => {
    form.resetFields()
  }

  return (
    <Form
      layout="inline"
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      requiredMark={false}
    >
      <Space className="flex-1" align="end" wrap={true}>
        <Form.Item label="路线描述" name="description">
          <Input placeholder="请输入路线描述" />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit">
            查询
          </Button>

          <Button type="default" htmlType="reset">
            重置
          </Button>
        </Space>
      </Space>
    </Form>
  )
}

export default SearchForm
