import React, { FC, useState } from 'react'
import { Spin, Form, Space, Select, Button, Radio, Input, Checkbox } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import {
  useRequestWithJWT,
  headersWithJWT,
  buildUrl,
  TOTAModule,
} from '../../../utils'

import './index.less'

const { Option } = Select

export interface IBaseValues {
  operation: 'upgrade' | 'rollback'
  task_des?: string
}

export interface IUpgradeValues extends IBaseValues {
  pad_id?: string
  master_machine_id?: string
  chassis_id?: string
  tight_coupling_id?: string
}
export interface IRollbackValues extends IBaseValues {
  modules: string
}

interface IVersion {
  id: string
  module: TOTAModule
  version: string
}

interface IVersionParams {
  module: TOTAModule
  version_name: string
}

interface IPorps {
  onSubmit: (values: IUpgradeValues | IRollbackValues) => void
}

const TaskForm: FC<IPorps> = (props) => {
  const [form] = Form.useForm()
  const [isUpgrade, setIsUpgrade] = useState<boolean>(true)
  const [padVersions, setPadVersions] = useState<IVersion[]>([])
  const [masterMachineVersions, setMasterMachineVersions] = useState<
    IVersion[]
  >([])
  const [chassisVersions, setChassisVersions] = useState<IVersion[]>([])
  const [tightCouplingVersions, setTightCouplingVersions] = useState<
    IVersion[]
  >([])

  /**
   * 根据类型获取版本下拉框
   */
  const fetchVersions = useRequestWithJWT({
    service: (params: IVersionParams) => ({
      url: buildUrl(`/tool/v1/ota/task/versions/${params.module}`, {
        version_name: params.version_name,
      }),
      method: 'GET',
      headers: headersWithJWT(),
    }),
    onSuccess: (res, params) => {
      const { data } = res || {}

      const { module } = params[0] || {}

      const hasData = Array.isArray(data)

      switch (module) {
        case 'pad':
          setPadVersions(hasData ? data : [])
          break

        case 'master_machine':
          setMasterMachineVersions(hasData ? data : [])
          break

        case 'chassis':
          setChassisVersions(hasData ? data : [])
          break

        case 'tight_coupling':
          setTightCouplingVersions(hasData ? data : [])
          break

        default:
          break
      }
    },
  })

  /**
   * 类型版本改变时触发
   * @param module 类型
   * @param value 版本
   */
  const onSearch = (module: TOTAModule) => (value: string) => {
    if (value) {
      fetchVersions.run({ module, version_name: value })
    } else {
      setVersions(module, [])
    }
  }

  /**
   * 根据不同模块，填充各个版本下拉框数据
   * @param module 模块名
   * @param versions 版本下拉框
   */
  const setVersions = (module: TOTAModule, versions: IVersion[]) => {
    switch (module) {
      case 'pad':
        return setPadVersions(versions)

      case 'master_machine':
        return setMasterMachineVersions(versions)

      case 'chassis':
        return setChassisVersions(versions)

      case 'tight_coupling':
        return setTightCouplingVersions(versions)

      default:
        return
    }
  }

  /**
   * 提交表单
   */
  const onFinish = (values: IUpgradeValues | IRollbackValues) => {
    const { operation } = values

    if (operation === 'upgrade') {
      const {
        pad_id,
        master_machine_id,
        chassis_id,
        tight_coupling_id,
      } = values as IUpgradeValues

      if (!pad_id && !master_machine_id && !chassis_id && !tight_coupling_id) {
        return form.setFields([
          {
            name: 'pad',
            value: pad_id,
            errors: ['Pad、上位机、下位机、紧组合版本不能都为空！'],
          },
          {
            name: 'master_machine',
            value: master_machine_id,
            errors: ['Pad、上位机、下位机、紧组合版本不能都为空！'],
          },
          {
            name: 'chassis',
            value: chassis_id,
            errors: ['Pad、上位机、下位机、紧组合版本不能都为空！'],
          },
          {
            name: 'tight_coupling',
            value: tight_coupling_id,
            errors: ['Pad、上位机、下位机、紧组合版本不能都为空！'],
          },
        ])
      }
    }

    props.onSubmit(values)
  }

  /**
   * 重置表单
   */
  const onReset = () => {
    form.resetFields()
  }

  /**
   * 当操作类型改变时触发
   */
  const onRadioChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'upgrade') {
      setIsUpgrade(true)
    } else {
      setIsUpgrade(false)
    }
  }

  return (
    <div className="OTATasks-right">
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{ required: '${label}不能为空!' }}
        onFinish={onFinish}
        onReset={onReset}
      >
        <Form.Item
          label="操作类型"
          name="operation"
          initialValue="upgrade"
          rules={[{ required: true }]}
        >
          <Radio.Group onChange={onRadioChange}>
            <Radio.Button value="upgrade">升级</Radio.Button>
            <Radio.Button value="rollback">回退</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {isUpgrade ? (
          <>
            <Form.Item label="Pad" name="pad_id">
              <Select
                placeholder="请输入Pad发布版本"
                showSearch={true}
                showArrow={false}
                allowClear={true}
                notFoundContent={
                  fetchVersions.loading ? <Spin size="small" /> : null
                }
                filterOption={false}
                onSearch={onSearch('pad')}
              >
                {Array.isArray(padVersions) &&
                  padVersions.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.version}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="上位机" name="master_machine_id">
              <Select
                placeholder="请输入上位机发布版本"
                showSearch={true}
                showArrow={false}
                allowClear={true}
                notFoundContent={
                  fetchVersions.loading ? <Spin size="small" /> : null
                }
                filterOption={false}
                onSearch={onSearch('master_machine')}
              >
                {Array.isArray(masterMachineVersions) &&
                  masterMachineVersions.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.version}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="下位机" name="chassis_id">
              <Select
                placeholder="请输入下位机发布版本"
                showSearch={true}
                showArrow={false}
                allowClear={true}
                notFoundContent={
                  fetchVersions.loading ? <Spin size="small" /> : null
                }
                filterOption={false}
                onSearch={onSearch('chassis')}
              >
                {Array.isArray(chassisVersions) &&
                  chassisVersions.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.version}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="紧组合" name="tight_coupling_id">
              <Select
                placeholder="请输入紧组合发布版本"
                showSearch={true}
                showArrow={false}
                allowClear={true}
                notFoundContent={
                  fetchVersions.loading ? <Spin size="small" /> : null
                }
                filterOption={false}
                onSearch={onSearch('tight_coupling')}
              >
                {Array.isArray(tightCouplingVersions) &&
                  tightCouplingVersions.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.version}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label="回退对象"
            name="modules"
            rules={[{ required: true }]}
          >
            <Checkbox.Group
              options={[
                { label: 'Pad', value: 'pad' },
                { label: '上位机', value: 'master_machine' },
                { label: '下位机', value: 'chassis' },
                { label: '紧组合', value: 'tight_coupling' },
              ]}
            />
          </Form.Item>
        )}
        <Form.Item label={`${isUpgrade ? '更新' : '回退'}说明`} name="task_des">
          <Input.TextArea
            placeholder="请输入更新说明(200字)"
            maxLength={200}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        </Form.Item>

        <div className="text-center">
          <Space>
            <Button type="default" htmlType="reset">
              清空
            </Button>

            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default TaskForm
