import { useHistory } from 'react-router-dom'
import useRequest from '@ahooksjs/use-request'
import { Modal, message } from 'antd'
import { CombineService, BaseOptions } from '@ahooksjs/use-request/lib/types'

export type TOTAModule = 'pad' | 'master_machine' | 'chassis' | 'tight_coupling'

interface IRequestConfig {
  service: CombineService<any, any[]>
  option?: BaseOptions<any, any[]>
  onSuccess: (res: any, params: any[]) => void
  onFail?: (res: any) => void
}

/**
 * 无需jwt的接口访问
 */
export const useRequestWithoutJWT = (config: IRequestConfig) => {
  const { service, option, onSuccess, onFail } = config

  const {
    loading,
    data,
    error,
    params,
    cancel,
    refresh,
    mutate,
    run,
    unmount,
  } = useRequest(service, {
    manual: true,
    throwOnError: true,
    debounceInterval: 500,
    loadingDelay: 200,
    onSuccess: (res, params) => {
      const { code, msg } = res

      if (code === 0) {
        onSuccess(res, params)
      } else {
        onFail
          ? onFail(res)
          : Modal.error({
              title: '出错啦~',
              content: <div dangerouslySetInnerHTML={{ __html: msg }} />,
            })
      }
    },
    onError: (e) => {
      if (e.message === 'Internal Server Error') {
        Modal.error({
          title: `网络错误`,
          content: `请检查网络连接后重试`,
        })
      } else {
        Modal.error({
          title: `服务器异常`,
          content: `请稍后重试`,
        })
      }
    },
    ...option,
  })

  return {
    loading,
    data,
    error,
    params,
    cancel,
    refresh,
    mutate,
    run,
    unmount,
  }
}

// 无需jwt的接口访问的header
export const headersWithoutJWT = {
  'Content-Type': 'application/json',
}

/**
 * 需要带jwt的接口访问
 */
export const useRequestWithJWT = (config: IRequestConfig) => {
  const { service, option, onSuccess, onFail } = config

  const history = useHistory()

  const goToLogin = (msg?: string) => {
    localStorage.clear()
    message.error(msg || '登录信息已失效，请重新登录')

    history.replace('/Login')
  }

  const {
    loading,
    data,
    error,
    params,
    cancel,
    refresh,
    mutate,
    run,
    unmount,
  } = useRequestWithoutJWT({
    service,
    option,
    onSuccess,
    onFail: (res) => {
      const { code, msg } = res

      if (
        // authorization为空
        code === 1101000 ||
        // jwt认证失败
        code === 1000003 ||
        // token过期
        code === 1000004 ||
        // jwt不可用
        code === 1000039 ||
        // 账号已停用
        code === 1091037
      ) {
        return goToLogin(msg)
      }

      onFail
        ? onFail(res)
        : Modal.error({
            title: '出错啦~',
            content: <div dangerouslySetInnerHTML={{ __html: msg }} />,
          })
    },
  })

  return {
    loading,
    data,
    error,
    params,
    cancel,
    refresh,
    mutate,
    run,
    unmount,
  }
}

// 需要带jwt的接口访问的header
export const headersWithJWT = () => {
  const jwt = localStorage.getItem('jwt')

  return {
    'Content-Type': 'application/json',
    Authorization: `${jwt}`,
  }
}

/**
 * 将URL中特殊的字符编码转换回原有符号
 */
const encode = (val: string): string => {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']')
}

const toString = Object.prototype.toString

/**
 * 判断类型是否为日期
 */
const isDate = (val: any): val is Date => {
  return toString.call(val) === '[object Date]'
}

/**
 * 判断类型是否为对象
 */
const isPlainObject = (val: any): val is Object => {
  return toString.call(val) === '[object object]'
}

/**
 * 拼接GET请求方法的url和params
 */
export const buildUrl = (url: string, params?: any): string => {
  if (!params) {
    return url
  }

  const parts: string[] = []

  Object.keys(params).forEach((key) => {
    const val = params[key]

    if (val === null || typeof val === 'undefined') {
      // 结束本次forEach
      return
    }

    //将所有的值都转成数组
    let values = []

    if (Array.isArray(val)) {
      values = val
      key += '[]'
    } else {
      values = [val]
    }

    values.forEach((val) => {
      if (isDate(val)) {
        val = val.toISOString()
      } else if (isPlainObject(val)) {
        // 普通对象，不包含formData类型等
        val = JSON.stringify(val)
      }

      parts.push(`${encode(key)}=${encode(val)}`)
    })
  })

  url += (url.indexOf('?') === -1 ? '?' : '&') + parts.join('&')

  return url
}
