import React, { FC, useState, useEffect } from 'react'
import {
  Form,
  Input,
  message,
  Modal,
  Spin,
  Radio,
  Row,
  Col,
  InputNumber,
  Space,
  Upload,
  Button,
} from 'antd'
import {
  useRequestWithJWT,
  headersWithJWT,
  TOTAModule,
  buildUrl,
} from '../../../utils'
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { RadioChangeEvent } from 'antd/lib/radio'
import { UploadFile, RcFile } from 'antd/lib/upload/interface'
import AppInfoParser from 'app-info-parser'
import OSS from 'ali-oss'
import SparkMD5 from 'spark-md5'
import { IDetail } from './index'

export interface IPacket {
  uid: string
  ali_key: string
  md5: string
}

export interface IImages {
  image_id: string
  repo: string
  repo_local: string
}

interface IValues {
  module: string
  version_name: string
  version_code: number
  change_log: string
  images?: IImages[]
}

interface IParams {
  id?: string
  change_log: string
  module: string
  images?: IImages[]
  packets: IPacket[]
  version_code: number
  version_name: string
}

interface ICheckParams {
  version: string
  module: string
}

interface IProps {
  loading: boolean
  visible: boolean
  id?: string
  OTADetail?: IDetail
  OSSClient?: OSS
  refreshOSS: () => void
  refreshOTAVersions: () => void
  hideModal: () => void
}

const EditForm: FC<IProps> = (props) => {
  const {
    loading,
    visible,
    id,
    hideModal,
    refreshOTAVersions,
    OTADetail,
    OSSClient,
    refreshOSS,
  } = props
  const [form] = Form.useForm()
  const [module, setModule] = useState<TOTAModule>('pad')
  const [packets, setPackets] = useState<IPacket[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>([])

  /**
   * 检验ota版本
   */
  const checkVersion = useRequestWithJWT({
    service: (params: ICheckParams) => {
      return {
        url: buildUrl(`/tool/v1/ota/version/check_version`, params),
        method: 'GET',
        headers: headersWithJWT(),
      }
    },
    onSuccess: (res, params) => {
      const { data } = res || {}
      const { is_usable, version_name } = data || {}
      const file = params[1] as RcFile

      if (is_usable) {
        uploadToOSS(file)
      } else {
        setFileList((fileList) => {
          const newFileList = [...fileList]
          const index = newFileList.findIndex((item) => item.uid === file.uid)

          if (index > -1) {
            newFileList.splice(index, 1)
          }
          return newFileList
        })

        form.setFields([
          {
            name: 'version_name',
            value: version_name,
            errors: ['版本名称已存在，请修改！'],
          },
        ])
      }
    },
  })

  /**
   * 新增/编辑ota版本
   */
  const editVersion = useRequestWithJWT({
    service: (params: IParams) => ({
      url: `/tool/v1/ota/versions`,
      method: 'POST',
      headers: headersWithJWT(),
      body: JSON.stringify(params),
    }),
    onSuccess: () => {
      refreshOTAVersions()
      message.success(id ? '编辑成功' : '新增成功')
      hideModal()
    },
  })

  // 弹出modal时获取OssToken
  useEffect(() => {
    if (visible) {
      refreshOSS()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    if (!id) {
      afterClose()
    } else {
      if (OTADetail) {
        const {
          images,
          packets,
          change_log,
          module,
          version,
          version_code,
        } = OTADetail

        setModule(module)

        form.setFieldsValue({
          module,
          version_name: version,
          version_code,
          change_log,
          images,
        })

        if (Array.isArray(packets) && packets.length > 0) {
          if (OSSClient) {
            const fileList = packets.map((item, index) => ({
              uid: `${index}`,
              name: item.ali_key.split('/').pop() as string,
              url: OSSClient.signatureUrl(item.ali_key),
              size: 0,
              type: '',
            }))

            const newPackets = packets.map((item, index) => ({
              uid: `${index}`,
              ali_key: item.ali_key,
              md5: item.md5,
            }))

            form.setFieldsValue({
              file: { fileList },
            })
            setFileList(fileList as UploadFile[])

            setPackets(newPackets)
          } else {
            message.error('OSS Token失效，请稍后重试')
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTADetail, id])

  /**
   * 根据uid，改变fileList中的status
   * @param uid 唯一标识
   * @param status 需要改为的状态
   */
  const changeFileListStatus = (uid: string, status: 'error' | 'done') => {
    setFileList((fileList) => {
      const newFileList = [...fileList]
      const index = newFileList.findIndex((item) => item.uid === uid)

      if (index > -1) {
        newFileList[index].status = status
      }
      return newFileList
    })
  }

  /**
   * 上传文件至oss
   * @param file 文件
   */
  const uploadToOSS = (file: RcFile) => {
    // 当前环境
    const environment = process.env.REACT_APP_ENVIRONMENT || 'dev'

    const fileKey = `${environment}/${module}/${file.name}`
    const { uid } = file

    if (OSSClient) {
      OSSClient.put(fileKey, file)
        .then(() => {
          changeFileListStatus(uid, 'done')

          getMd5(file)
            .then((md5) => {
              setPackets((packets) => [
                ...packets,
                {
                  uid: file.uid,
                  ali_key: fileKey,
                  md5,
                },
              ])
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)

          changeFileListStatus(uid, 'error')

          message.error('上传失败，请重试')
        })
    } else {
      changeFileListStatus(uid, 'error')

      message.error('上传失败，请稍后重试')
    }
  }

  /**
   * 计算文件md5
   */
  const getMd5 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const blobSlice = File.prototype.slice
      const chunkSize = 2097152 // Read in chunks of 2MB
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      const loadNext = () => {
        const start = currentChunk * chunkSize
        const end =
          start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      fileReader.onload = function () {
        spark.append(fileReader.result as ArrayBuffer)
        currentChunk++

        if (currentChunk < chunks) {
          loadNext()
        } else {
          const md5 = spark.end()
          resolve(md5)
        }
      }

      fileReader.onerror = function (err) {
        reject(err)
      }

      loadNext()
    })
  }

  /**
   * 提交表单
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    form.validateFields().then((values: IValues) => {
      const { id } = props
      const { module, version_name, version_code, change_log, images } = values

      const params: IParams = {
        id,
        module,
        version_name,
        version_code,
        change_log,
        images,
        packets: packets,
      }

      editVersion.run(params)
    })
  }

  /**
   * 当所属对象改变时触发
   */
  const onRadioChange = (e: RadioChangeEvent) => {
    setModule(e.target.value)
    // 切换radio时清空部分表单数据
    setFileList([])
    setPackets([])

    form.resetFields(['version_name', 'version_code', 'change_log'])
  }

  /**
   * 检查是否填写了version_name
   * @param succeed 已填写回调
   * @param failed 未填写回调
   */
  const checkFormVersion = (
    succeed: (version: string) => void,
    failed?: (version: string) => void
  ) => {
    const version = form.getFieldValue('version_name')

    if (!!version) {
      succeed(version)
    } else {
      form.setFields([
        {
          name: 'version_name',
          value: version,
          errors: ['请先输入版本名称！'],
        },
      ])

      failed && failed(version)
    }
  }

  /**
   * 上传文件前的验证
   */
  const beforeUpload = (file: RcFile) => {
    if (module === 'pad') {
      if (fileList.length < 1) {
        const parser = new AppInfoParser(file)

        parser
          .parse()
          .then((result: any) => {
            const { versionName, versionCode } = result || {}

            form.setFieldsValue({
              version_name: versionName,
              version_code: versionCode,
            })

            checkVersion.run({ module, version: versionName }, file)
          })
          .catch((err: any) => {
            console.log(err)

            message.error('上传失败，请检查apk文件是否正确')
          })
      } else {
        uploadToOSS(file)
      }
    } else {
      checkFormVersion((version) => {
        checkVersion.run({ module, version }, file)
      })
    }

    return false
  }

  /**
   * 根据不同module限制不同上传格式
   */
  const getUploadAccept = (): string => {
    switch (module) {
      case 'pad':
        return '.apk'

      case 'master_machine':
        return '.tar.gz'

      default:
        return '.zip'
    }
  }

  /**
   * Upload上传改变时触发
   */
  const handleUploadChange = (obj: { fileList: UploadFile[] }) => {
    const { fileList: newFileList } = obj

    if (newFileList.length > fileList.length) {
      newFileList[newFileList.length - 1].status = 'uploading'
    }

    if (module === 'pad') {
      setFileList((fileList) => {
        if (newFileList.length > fileList.length) {
          newFileList[newFileList.length - 1].status = 'uploading'
        }
        return newFileList
      })
    } else {
      checkFormVersion(() => {
        setFileList((fileList) => {
          if (newFileList.length > fileList.length) {
            newFileList[newFileList.length - 1].status = 'uploading'
          }
          return newFileList
        })
      })
    }
  }

  /**
   * Upload上传删除时触发
   */
  const handleUploadRemove = (file: UploadFile) => {
    setPackets((packets) => {
      const newPackets = [...packets]
      const index = newPackets.findIndex((item) => item.uid === file.uid)

      if (index > -1) {
        newPackets.splice(index, 1)
      }

      return newPackets
    })

    setFileList((fileList) => {
      const newFileList = [...fileList]
      const index = newFileList.findIndex((item) => item.uid === file.uid)

      if (index > -1) {
        newFileList.splice(index, 1)
      }

      return newFileList
    })

    return false
  }

  const afterClose = () => {
    setModule('pad')
    setFileList([])
    setPackets([])
    form.resetFields()
  }

  return (
    <Modal
      title={`${id ? '编辑' : '新增'}版本`}
      destroyOnClose={true}
      keyboard={false}
      maskClosable={false}
      width="90%"
      centered={true}
      afterClose={afterClose}
      closable={!editVersion.loading}
      visible={visible}
      okText="提交"
      onOk={handleSubmit}
      okButtonProps={{ disabled: editVersion.loading }}
      cancelText="取消"
      onCancel={hideModal}
      cancelButtonProps={{
        disabled: editVersion.loading,
      }}
    >
      <Spin spinning={loading || editVersion.loading}>
        <Form
          form={form}
          layout="vertical"
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: '${label}不能为空!' }}
        >
          <Form.Item
            label="所属对象"
            name="module"
            rules={[{ required: true }]}
            initialValue="pad"
          >
            <Radio.Group onChange={onRadioChange} disabled={!!id}>
              <Radio.Button value="pad">Pad</Radio.Button>
              <Radio.Button value="master_machine">上位机</Radio.Button>
              <Radio.Button value="chassis">下位机</Radio.Button>
              <Radio.Button value="tight_coupling">紧组合</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="版本号"
                name="version_name"
                rules={[{ required: true }]}
                getValueFromEvent={(event) =>
                  event.target.value.replace(/[^\x21-\x7e]/g, '')
                }
              >
                <Input
                  placeholder="请输入版本名称"
                  maxLength={40}
                  disabled={module === 'pad'}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="版本序号"
                name="version_code"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="请输入版本号"
                  precision={0}
                  min={0}
                  style={{ width: '100%' }}
                  disabled={module === 'pad'}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="升级包" name="file" rules={[{ required: true }]}>
            <Upload
              accept={getUploadAccept()}
              beforeUpload={beforeUpload}
              fileList={fileList}
              onChange={handleUploadChange}
              onRemove={handleUploadRemove}
            >
              {fileList.length >= 5 ? null : (
                <Button icon={<UploadOutlined />}>上传文件</Button>
              )}
            </Upload>
          </Form.Item>

          {module === 'master_machine' && (
            <Form.List
              name="images"
              initialValue={[
                {
                  image_id: '',
                  repo: '',
                  repo_local: '',
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => {
                    return (
                      <div
                        className="flex flex_space-between"
                      >
                        <div className="flex-1 OTAVersions-images">
                          <Form.Item
                            label="docker仓库地址"
                            name={[field.name, 'repo']}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="请输入docker仓库地址" />
                          </Form.Item>

                          <Form.Item
                            label="本地仓库地址"
                            name={[field.name, 'repo_local']}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="请输入本地仓库地址" />
                          </Form.Item>

                          <Form.Item
                            label="hash值"
                            name={[field.name, 'image_id']}
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="请输入hash值" />
                          </Form.Item>
                        </div>

                        <Space style={{ width: '80px' }}>
                          {fields.length < 20 &&
                            fields.length - index === 1 && (
                              <PlusSquareOutlined
                                style={{ fontSize: '36px' }}
                                onClick={() => add()}
                              />
                            )}

                          {fields.length > 1 && (
                            <MinusSquareOutlined
                              style={{ fontSize: '36px' }}
                              onClick={() => remove(field.name)}
                            />
                          )}
                        </Space>
                      </div>
                    )
                  })}
                </>
              )}
            </Form.List>
          )}

          <Form.Item label="版本描述" name="change_log">
            <Input.TextArea
              placeholder="请输入版本描述(200字)"
              maxLength={200}
              autoSize={{ minRows: 3, maxRows: 10 }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default EditForm
