import React, { FC, useState, Key } from 'react'
import { Layout, Spin, Table, Space, Button, message, Modal } from 'antd'
import { useRequestWithJWT, headersWithJWT, buildUrl } from '../../../utils'
import { getTableColumns } from './tableColumns'
import moment from 'moment'
import SearchForm, { IValues } from './SearchForm'

const { Content } = Layout

interface IParams {
  site_ids?: string[]
  vehicle_ids?: string[]
  status: string
  start_time?: string
  end_time?: string
  page: number
  page_size: number
}

interface ISummary {
  site_name: string
  vehicle_nums: number
}

const OTAProgresses: FC = () => {
  const [params, setParams] = useState<IParams>({
    status: 'failed',
    page: 1,
    page_size: 10,
  })
  const [tableTotal, setTableTotal] = useState<number>(0)
  const [tableDate, setTableDate] = useState<any[]>([])
  const [summary, setSummary] = useState<ISummary[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const hasSelected =
    Array.isArray(selectedRowKeys) && selectedRowKeys.length > 0

  /**
   * 获取OTA进度列表
   */
  const getOTAProgresses = useRequestWithJWT({
    service: {
      url: buildUrl(`/tool/v1/ota/progresses`, params),
      method: 'GET',
      headers: headersWithJWT(),
    },
    option: {
      manual: false,
      refreshDeps: [params],
    },
    onSuccess: (res) => {
      const { data, total } = res || {}
      const { progress_list, progress_summary } = data || {}

      setTableTotal(total || 0)
      setTableDate(progress_list || [])
      setSummary(progress_summary || [])
    },
  })

  /**
   * 获取OTA进度状态详情
   */
  const getOTAFailedDetail = useRequestWithJWT({
    service: (id: string) => ({
      url: `/tool/v1/ota/progresses/${id}`,
      method: 'GET',
      headers: headersWithJWT(),
    }),
    onSuccess: (res) => {
      const { data } = res || {}
      const { status_detail } = data || {}

      Modal.info({
        title: '原因',
        content: <div dangerouslySetInnerHTML={{ __html: status_detail }} />,
      })
    },
  })

  /**
   * 批量重新下发OTA
   */
  const resetOTA = useRequestWithJWT({
    service: (params: { progress_ids: Key[] }) => ({
      url: `/tool/v1/ota/progresses`,
      method: 'PUT',
      headers: headersWithJWT(),
      body: JSON.stringify(params),
    }),
    onSuccess: () => {
      getOTAProgresses.refresh()
      setSelectedRowKeys([])
      message.success('重新下发成功')
    },
  })

  /**
   * 批量删除未开始的OTA
   */
  const deleteOTA = useRequestWithJWT({
    service: (id: Key[]) => ({
      url: `/tool/v1/ota/progresses/${id}`,
      method: 'DELETE',
      headers: headersWithJWT(),
    }),
    onSuccess: () => {
      getOTAProgresses.refresh()
      setSelectedRowKeys([])
      message.success('删除成功')
    },
  })

  /**
   * 点击查询按钮触发
   */
  const onSubmit = (values: IValues) => {
    const { site_ids, vehicle_ids, status, time } = values

    setParams((params) => ({
      ...params,
      site_ids,
      vehicle_ids,
      status,
      page: 1,
      start_time:
        time && time[0] ? moment(time[0]).format('YYYY-MM-DD') : undefined,
      end_time:
        time && time[1] ? moment(time[1]).format('YYYY-MM-DD') : undefined,
    }))
  }

  /**
   * 点击删除按钮触发
   */
  const onDelete = () => {
    if (hasSelected) {
      Modal.confirm({
        title: '确定要删除所选项目吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          deleteOTA.run(selectedRowKeys)
        },
      })
    } else {
      message.error('请选择需要删除的项目')
    }
  }

  /**
   * 点击重新下发按钮触发
   */
  const onResetOTA = () => {
    if (hasSelected) {
      Modal.confirm({
        title: '确定要重新下发所选项目吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          resetOTA.run({ progress_ids: selectedRowKeys })
        },
      })
    } else {
      message.error('请选择需要重新下发的项目')
    }
  }

  /**
   * 点击已经失败/已超时展示详细信息
   */
  const showOTAFailedDetail = (id: string) => () => {
    getOTAFailedDetail.run(id)
  }

  /**
   * 翻页
   */
  const onPaginationchange = (page: number) => {
    setParams((params) => ({ ...params, page }))
  }

  /**
   * 选择table项触发
   */
  const onSelectChange = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  /**
   * 根据升级失败数组渲染总结模块
   * @param summary 升级失败数组
   */
  const getSummary = (summary: ISummary[]): React.ReactNode =>
    Array.isArray(summary) && summary.length > 0 ? (
      <div className="p-10 bg-red">
        {summary.map((item, index) => (
          <span className="text-red" key={index}>
            {`${item.site_name} ${item.vehicle_nums}台车 升级失败；`}
          </span>
        ))}
      </div>
    ) : null

  /**
   * 根据升级状态渲染Table功能按钮
   * @param status 当前升级状态
   */
  const getTableButton = (status: string): React.ReactNode => {
    switch (status) {
      case 'not_started':
        return (
          <Button danger={true} disabled={!hasSelected} onClick={onDelete}>
            删除
          </Button>
        )

      case 'failed':
      case 'timed_out':
        return (
          <Button type="primary" disabled={!hasSelected} onClick={onResetOTA}>
            重新下发
          </Button>
        )

      default:
        break
    }

    return null
  }

  /**
   * 根据升级状态渲染Table多选框
   * @param status 当前升级状态
   */
  const getRowSelection = (status: string) => {
    switch (status) {
      case 'success':
      case 'in_progress':
        return undefined

      default:
        return {
          selectedRowKeys,
          onChange: onSelectChange,
        }
    }
  }

  return (
    <Spin
      spinning={
        getOTAProgresses.loading ||
        resetOTA.loading ||
        deleteOTA.loading ||
        getOTAFailedDetail.loading
      }
    >
      <Content className="app_main">
        <Space className="app_content" direction="vertical" size="middle">
          <SearchForm onSubmit={onSubmit} />

          {getSummary(summary)}

          {getTableButton(params.status)}

          <Table
            className="app_main_table"
            rowKey="id"
            locale={{ emptyText: '暂无数据' }}
            columns={getTableColumns(showOTAFailedDetail)}
            dataSource={tableDate}
            rowSelection={getRowSelection(params.status)}
            pagination={{
              current: params.page,
              total: tableTotal,
              onChange: onPaginationchange,
              showSizeChanger: false,
            }}
          />
        </Space>
      </Content>
    </Spin>
  )
}

export default OTAProgresses
